<template>
  <v-layout column class="drawing-mission">
    <v-flex d-flex grow>
      <v-flex class="canvas-container-wrap">
        <div class="canvas-container">
          <canvas
            v-once
            ref="canvas_remote"
            class="canvas"
            :width="width"
            :height="height"
            :style="{ backgroundColor: bgColor }"
          />
        </div>
        <div class="canvas-container">
          <canvas
            v-once
            ref="canvas_local"
            class="canvas local"
            :width="width"
            :height="height"
            :style="{ opacity: localCanvasOpacity }"
            @mousedown="onMouseDown"
            @mouseup="onMouseUp"
            @mouseleave="onMouseLeave"
            @mouseenter="onMouseEnter"
            @mousemove="onMouseMove"
          />
        </div>
      </v-flex>
    </v-flex>
    <v-flex shrink v-if="isHost && isPictionary">
      <v-layout row>
        <v-flex class="dropdowns">
          <v-select
            :value="assignedTeamID"
            :items="drawingTeams"
            item-text="name"
            item-value="teamID"
            label="Team"
            @input="onChangeTeam"
          />
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import uniqid from "uniqid"
import { EventBus } from "@/Eventbus"
import { mapState, mapGetters, mapActions } from "vuex"
import simplify from "simplify-js"
import smooth from "smooth-polyline"

let canvas
let canvasCtx
let canvasLocal
let canvasLocalCtx

const DRAWING_EACH_TEAM = "Draw: Each Team"
const DRAWING_PICTIONARY = "Draw: Pictionary"
const DRAWING_EVERYONE = "Draw: Everyone"
const SIMPLIFICATION_TOLERANCE = 2

export default {
  name: "Drawing",
  props: {
    width: Number,
    height: Number,
    bgColor: {
      type: String,
      default: "#9e9e9e"
    }
  },
  data: function() {
    return {
      localPoints: [],
      isMouseDown: false,
      isOutOfBounds: false
    }
  },
  computed: {
    ...mapState("group", { globalTeamID: "currentGlobalTeam" }),
    ...mapState("drawing", [
      "points",
      "lineColorRgb",
      "lineColorRgba",
      "lineWidth",
      "alpha",
      "isPen"
    ]),
    ...mapState("drawing", { assignedTeamID: "teamID" }),
    ...mapGetters("auth", ["isHost", "isAudit", "isSpectator", "user"]),
    ...mapGetters({
      missionID: "currentMission",
      mission: "getCurrentMission",
      teams: "chats"
    }),
    drawingTeamID() {
      if (this.behavior === DRAWING_EVERYONE) {
        return "0"
      } else if (this.behavior === DRAWING_EACH_TEAM) {
        return this.isTeamGlobal ? this.globalTeamID : this.playerTeamID
      } else if (this.behavior === DRAWING_PICTIONARY) {
        return this.assignedTeamID
      } else {
        return null
      }
    },
    isPictionary() {
      return this.behavior === DRAWING_PICTIONARY
    },
    behavior() {
      return this.mission ? this.mission.behavior : null
    },
    canDraw() {
      return (
        this.drawingTeamID === this.playerTeamID ||
        this.drawingTeamID === "0" ||
        this.isHost
      )
    },
    playerTeamID() {
      return String(this.$store.getters.user.teamID)
    },
    localCanvasOpacity() {
      return this.isPen ? this.alpha : 1
    },
    drawingTeams() {
      return Object.entries(this.teams || {}).map(([id, team]) => ({
        teamID: id,
        name: team.name
      }))
    },
    isTeamGlobal() {
      return this.isHost || this.isAudit || this.isSpectator
    }
  },
  created() {
    if (this.isPictionary) this.subscribeToAssignedTeam()
    EventBus.$on("drawingSubmit", this.onDrawingSubmit)
  },
  mounted() {
    this.$nextTick(() => {
      canvas = this.$refs.canvas_remote
      canvasCtx = canvas.getContext("2d")
      canvasLocal = this.$refs.canvas_local
      canvasLocalCtx = canvasLocal.getContext("2d")
    })
  },
  async beforeDestroy() {
    // save the image if a host and if there is some data on the canvas
    if (this.isHost && this.behavior === DRAWING_PICTIONARY) {
      const n = Object.keys(this.points || {}).length
      if (n) {
        const url = await this.captureImage()
        const playsArray = this.$store.getters.plays
        const found = playsArray.find(
          ({ missionID, correct }) =>
            missionID === this.missionID && correct && correct.image
        )
        await this.$store.dispatch("addPlay", {
          missionID: this.missionID,
          points: 0,
          score: 0,
          behavior: "Draw: Pictionary",
          answer: [],
          correct: { image: url },
          teamID: this.drawingTeamID,
          userID: 0,
          id: found ? found.id : null
        })
      }
    }
    canvas = null
    canvasCtx = null
    canvasLocal = null
    canvasLocalCtx = null
    EventBus.$off("drawingSubmit", this.onDrawingSubmit)
    console.log("Drawing component destroyed")
  },
  watch: {
    points: {
      handler(newValue) {
        if (!canvasCtx) return console.log("The canvas is still null")
        canvasCtx.clearRect(0, 0, this.width, this.height)
        if (!newValue) return
        const array = Object.values(newValue)
        array.forEach(({ data }) => {
          let obj = null
          try {
            const { lineWidth, lineColor, points } = JSON.parse(data)
            obj = { lineWidth, lineColor, points, ctx: canvasCtx }
          } catch (e) {
            return console.warn("Cannot parse picture data")
          }
          this.draw(obj, true)
        })
      },
      deep: true,
      immediate: true
    },
    drawingTeamID: {
      handler(newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          console.log("subscribeToTeamPaths", newValue)
          this.subscribeToTeamPaths({ teamID: newValue })
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions("drawing", [
      "updateDrawingTeam",
      "pushTeamPath",
      "subscribeToAssignedTeam",
      "subscribeToTeamPaths",
      "uploadImage"
    ]),
    async onChangeTeam(teamID) {
      await this.updateDrawingTeam({ teamID })
    },
    onMouseLeave() {
      this.isMouseOver = false
      this.isMouseDown = false
      this.onMouseUp()
    },
    onMouseEnter() {
      this.isMouseOver = true
    },
    async onMouseUp(e) {
      if (this.isMouseOver && this.canDraw) this.plotThePoint(e)
      this.isMouseDown = false
      if (!this.localPoints.length) return

      const points = simplify(
        this.localPoints.map(([x, y]) => ({ x, y })),
        SIMPLIFICATION_TOLERANCE
      ).map(obj => [obj.x, obj.y])

      this.pushTeamPath({
        teamID: this.drawingTeamID,
        lineColor: this.isPen ? this.lineColorRgba : this.bgColor,
        points,
        lineWidth: this.lineWidth
      })
      canvasLocalCtx.clearRect(0, 0, this.width, this.height)
      this.localPoints = []
    },
    onMouseDown() {
      this.isMouseDown = true
    },
    plotThePoint(e) {
      const canvasWidth = canvasLocal.clientWidth
      // calculate the diff between the actual canvas sizes and
      // the displayed one
      // calc a scalar to map the actual mouse x y to the right ones
      const scale = this.width > canvasWidth ? this.width / canvasWidth : 1
      // cross-browser canvas coordinates
      const x = e.offsetX || e.layerX - e.offsetLeft
      const y = e.offsetY || e.layerY - e.offsetTop
      // apply the scalar
      const newX = Math.round(x * scale)
      const newY = Math.round(y * scale)
      const lineColor = this.isPen ? this.lineColorRgb : this.bgColor
      this.localPoints.push([newX, newY])
      this.draw({
        lineWidth: this.lineWidth,
        lineColor: lineColor,
        points: this.localPoints,
        ctx: canvasLocalCtx,
        alpha: this.alpha
      })
    },
    onMouseMove(e) {
      if (this.isMouseDown && this.canDraw) this.plotThePoint(e)
    },
    draw({ lineWidth, lineColor, points, ctx }, foo) {
      if (!points.length) return
      ctx.lineWidth = lineWidth
      ctx.strokeStyle = lineColor
      ctx.lineJoin = "round"
      ctx.lineCap = "round"
      ctx.beginPath()
      ctx.moveTo(points[0][0], points[0][1])
      ctx.globalCompositeOperation = "source-over"
      if (points.length < 2) {
        ctx.lineTo(points[0][0] + 1, points[0][1])
      } else {
        const smoothed = foo ? smooth(smooth(points)) : points
        for (let i = 1; i < smoothed.length; i++) {
          ctx.lineTo(smoothed[i][0], smoothed[i][1])
        }
      }
      ctx.stroke()
    },
    async captureImage() {
      if (!canvasCtx) throw new Error("No canvas data available")
      canvasCtx.save()
      canvasCtx.globalCompositeOperation = "destination-over"
      canvasCtx.fillStyle = this.bgColor
      canvasCtx.fillRect(0, 0, canvas.width, canvas.height)
      canvasCtx.restore()

      const canvasToJpg = canvas =>
        new Promise(resolve =>
          canvas.toBlob(blob => resolve(blob), "image/jpeg", 0.7)
        )

      const file = await canvasToJpg(canvas)
      const imageID = uniqid()
      const filename = `${imageID}-${this.user.username}.png`
      const url = await this.uploadImage({ filename, file })
      return url
    },
    async onDrawingSubmit(caption = "") {
      const url = await this.captureImage()
      EventBus.$emit("drawingResponse", { caption, url })
    }
  }
}
</script>

<style lang="scss">
.drawing-mission {
  position: relative;
  user-select: none;
  .canvas-container-wrap {
    position: relative;
  }
  .canvas-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-content: center;
  }
  .canvas {
    box-sizing: border-box;
    position: relative;
    display: block;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
  }
  .canvas.local {
    background-color: transparent;
  }
  .dropdowns:not(:last-child) {
    margin-right: 5px;
  }
  .v-input__slot {
    margin-bottom: 0 !important;
  }
  .v-input {
    user-select: none;
  }
  .v-select__selection--comma {
    margin: 7px 4px 4px 0;
    font-size: 13px;
  }
  .v-text-field__details {
    display: none;
  }
}
</style>
