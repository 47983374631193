var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { staticClass: "drawing-mission", attrs: { column: "" } },
    [
      _c(
        "v-flex",
        { attrs: { "d-flex": "", grow: "" } },
        [
          _c("v-flex", { staticClass: "canvas-container-wrap" }, [
            _c("div", { staticClass: "canvas-container" }, [_vm._m(0)]),
            _c("div", { staticClass: "canvas-container" }, [_vm._m(1)])
          ])
        ],
        1
      ),
      _vm.isHost && _vm.isPictionary
        ? _c(
            "v-flex",
            { attrs: { shrink: "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "" } },
                [
                  _c(
                    "v-flex",
                    { staticClass: "dropdowns" },
                    [
                      _c("v-select", {
                        attrs: {
                          value: _vm.assignedTeamID,
                          items: _vm.drawingTeams,
                          "item-text": "name",
                          "item-value": "teamID",
                          label: "Team"
                        },
                        on: { input: _vm.onChangeTeam }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("canvas", {
      ref: "canvas_remote",
      staticClass: "canvas",
      style: { backgroundColor: _vm.bgColor },
      attrs: { width: _vm.width, height: _vm.height }
    })
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("canvas", {
      ref: "canvas_local",
      staticClass: "canvas local",
      style: { opacity: _vm.localCanvasOpacity },
      attrs: { width: _vm.width, height: _vm.height },
      on: {
        mousedown: _vm.onMouseDown,
        mouseup: _vm.onMouseUp,
        mouseleave: _vm.onMouseLeave,
        mouseenter: _vm.onMouseEnter,
        mousemove: _vm.onMouseMove
      }
    })
  }
]
render._withStripped = true

export { render, staticRenderFns }